import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import PropTypes from 'prop-types';

import SEO from 'utilities/components/seo';
import { Navigation } from 'utilities/components/navigation/component';
import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { Blog } from 'features/blog/blog';
import { Footer } from 'utilities/components/footer/component';

import { routes } from 'utilities/routes';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import { setCanonicalLink } from 'utilities/helpers/setCanonicalLink';
import { locationShape } from 'utilities/data/proptypesShapes';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';

const AllAboutDoors = ({ page, location, tag }) => {
    const breadcrumbs = [routes.home, routes.wszystkoODrzwiach];
    const { metaTitle, metaDescription, description } = useSeoTexts('Artykuły');

    return (
        <main className="all-about-doors">
            <SEO
                title={ metaTitle || 'Dowiedz się więcej o drzwiach, blog | Barański Drzwi' }
                description={
                    metaDescription
                    || 'Przeczytaj nasze artykuły i dowiedz się więcej o drzwiach wewnętrznych, zewnętrznych i technicznych. Odwiedź blog Barański Drzwi.'
                }
                canonicalLink={ setCanonicalLink() }
                robotsDirective={ !page || +page === 1 ? '' : 'noindex, follow' }
            />
            <Navigation />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <Blog type="wszystkie" page={ page } tag={ tag } location={ location } />
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default AllAboutDoors;

AllAboutDoors.propTypes = {
    page: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    location: PropTypes.shape(locationShape).isRequired
};
